import React from "react"
import {
  FilterContainer,
  CheckboxContainer,
} from "./SystemSelectionView.styles"
import { useSystemSelection } from "./useSystemSelection"

export const SystemSelection = props => {
  const { handleChange, selectedSystems, systems } = useSystemSelection(props)
  return (
    <FilterContainer>
      {systems.map((system, index) => {
        return (
          <CheckboxContainer key={index}>
            <input
              type="checkbox"
              id={system.title}
              name={system.title}
              onChange={e => handleChange(e)}
              checked={selectedSystems.includes(system.title)}
            />
            <label htmlFor={system.title}>{system.title}</label>
          </CheckboxContainer>
        )
      })}
    </FilterContainer>
  )
}
