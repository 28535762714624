import React from "react"
import { Container } from "./ActionMenu.styles"

interface IActionMenu {
  children: React.ReactNode
}
export const ActionMenu = (props: IActionMenu) => {
  const { children } = props

  return <Container>{children}</Container>
}
