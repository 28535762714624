import styled from "styled-components"

export const PvPRoundContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.5em 0;

  > div {
    text-align: center;
  }
`
