import styled from "styled-components"

export interface IInputProps {
  hasError?: boolean
}

export const Input = styled.input`
  border: ${(props: IInputProps) =>
    props.hasError
      ? "3px solid red"
      : ({ theme }) => `1px solid ${theme.grapefruit}`};
  box-sizing: border-box;
  font-size: 1.5rem;
  padding: 9px;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Radio = styled(Input)`
  display: none;

  + label svg {
    visibility: hidden;
  }

  &:checked + label {
    svg {
      visibility: visible;
    }
  }
`
