import { useContext } from "react"
import { PlayerContext } from "../../contexts"
import { useLocalStorage } from "../../hooks"

export function useLeaderboard() {
  const { player } = useContext(PlayerContext)
  const { getStorageKeys, issetStorage, getStorage } = useLocalStorage()
  const { score } = getStorageKeys()

  const leaderBoardData = player.map(p => {
    return { name: p.name, score: 0, avatar:p.avatar }
  })


  if (issetStorage(score)) {
    const currentGameScores = getStorage(score, "object")

    currentGameScores.forEach(game => {
      game.score.forEach(score => {
        const index = leaderBoardData.findIndex(entry => entry.name === score.name)
        leaderBoardData[index].score += score.score
      })
    })
  }

  const sortByPoints = (array, sortParameter: string) => {
    array.sort((a, b) => a[sortParameter] - b[sortParameter]).reverse()
    return array
  }

  sortByPoints(leaderBoardData, "score")

  return { playerdata: leaderBoardData }
}
