import styled from "styled-components"

export const FixtureWrap = styled.div`
  font-size: 1, 5rem;
  width: 100%;
  text-align: center;
  @media (min-width: 768px) {
    margin: 2em 0;
  }
`
interface IPlayerContainerProps {
  isPlayed: boolean
  isWinner: boolean
}
// TODO refactor to element
export const PlayerContainer = styled.div<IPlayerContainerProps>`
  background-color: ${({ theme, isPlayed }) =>
    isPlayed ? theme.kotc_red.normal : "transparent"};
  color: ${({ theme, isPlayed }) =>
    isPlayed ? theme.kotc_creme : theme.kotc_red.normal};
  border: 1px solid ${({ theme }) => theme.kotc_red.normal};
  font-size: 1.5rem;
  padding: 0.5em;
  position: relative;
  cursor: ${({ isPlayed }) => (!isPlayed ? "pointer" : "not-allowed")};
  text-decoration: ${props =>
    props.isPlayed === false
      ? "none"
      : props.isWinner === true
      ? "underline"
      : "line-through"};

  &:after {
    display: ${({ isWinner, isPlayed }) =>
      isPlayed && isWinner ? "block" : "none"};
    content: "";
    width: 30px;
    height: 24px;
    background-image: url("/logo_krone.svg");
    position: absolute;
    top: -17px;
    left: -12px;
    background-size: contain;
  }

  @media (min-width: 768px) {
    padding: 1em;
    text-align: center;
    max-width: 320px;
  }
`
