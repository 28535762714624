import React from "react"
import { GameContainer, Title } from "./GameView.styles"
import { useGame } from "./useGame"

export const Game = props => {
  const { isSelected, handleClick, title } = useGame(props)
  return (
    <GameContainer onClick={() => handleClick()}>
      <input
        type="checkbox"
        name={title}
        checked={isSelected}
        onChange={() => console.log("change")}
      />
      <Title selected={isSelected}>
        <span>{title}</span>
      </Title>
    </GameContainer>
  )
}
