import styled from "styled-components"

export const ErrorParagraph = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 1.2em;
  color: red;
  bottom: 1em;
  margin: 0.5em 0;
`
