import styled from "styled-components"

export const Table = styled.table`
  margin: 1.5rem auto;
  width: 320px;
  border-spacing: 0; /* Removes the cell spacing via CSS */
  border-collapse: collapse;
  border-radius: 8px;
  /* font-size: 1.5em; */

  td {
    padding: 0.5rem;
  }
  tbody {
    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* border: 1px solid ${({ theme }) => theme.kotc_red.normal}; */
    }
  }
`
