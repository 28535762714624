import { useContext, useState } from "react"
import { useLocalStorage } from "../../hooks"
import { TournamentContext, GameStageContext } from "../../contexts"

export function useTournamentDashboard() {
  const [showEndScreen, setShowEndScreen] = useState(false)
  const { currentGame, chooseRandomGame, gameIsRunning } = useContext(
    GameStageContext
  )
  const { name } = useContext(TournamentContext)
  const { issetStorage, getStorage, getStorageKeys } = useLocalStorage()
  const { gamesPool: storageGamesPool } = getStorageKeys()
  const gamesLeftToPlay =
    issetStorage(storageGamesPool) &&
    getStorage(storageGamesPool, "object").length > 0
      ? true
      : false
  const dashboardHeadline = !gameIsRunning ? name : currentGame?.title ?? ""

  const showWinningScreen = () => {
    setShowEndScreen(true)
  }

  return {
    name,
    chooseRandomGame,
    gameIsRunning,
    dashboardHeadline,
    gamesLeftToPlay,
    title: currentGame.title,
    showEndScreen,
    showWinningScreen,
  }
}
