import { useState, useContext } from "react"
import { FixturesContext } from "../../../../../../contexts"
export function usePvPRound() {

  const { setRoundPlayed, allRoundsPlayed } = useContext(FixturesContext)

  const [roundUpPlayed, setRoundUpPlayed] = useState(false)

  const handleRoundPlayed = e => {
    if (e.target.dataset.name === undefined) return
    if (allRoundsPlayed || roundUpPlayed) return
    setRoundUpPlayed(true)
    setRoundPlayed()
  }

  return { handleRoundPlayed, isPlayed: roundUpPlayed }
}
