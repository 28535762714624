import { useState, useContext } from "react"
import {  FixturesContext } from "../../../../../../contexts"
import { IPlayer } from "../../../../../../types"
export interface ITwoOnTwo {
  isPlayed: boolean
  team: IPlayer[]
  setIsPlayed: Function
}

export function useTwoOnTwoTeam(props: ITwoOnTwo) {
  const { isPlayed, team, setIsPlayed } = props
  const { setRoundPlayed, roundPoints, setRoundPoints } = useContext(
    FixturesContext
  )
  const [isWinner, setIsWinner] = useState(() => false)

  const evaluateScore = e => {
    if (isPlayed) return
    setIsPlayed(true)
    setRoundPlayed()
    const winningTeam = e.currentTarget.dataset.team.split(",")

    const newScore = roundPoints.filter(
      obj => obj.name !== winningTeam[0] && obj.name !== winningTeam[1]
    )
    setRoundPoints([
      ...newScore,
      { name: winningTeam[0], score: 2 },
      { name: winningTeam[1], score: 2 },
    ])

    setIsWinner(() => true)
  }

  return { evaluateScore, isWinner, team, isPlayed }
}
