import React from "react"
import { PlayerContainer } from "./TournamentBranchPlayerView.styles"
import { useTournamentBranchPlayer } from "./useTournamentBranchPlayer"
import {IBranchWinner} from '../../types'

interface ITournamentBranchPlayer {
  isPlayed: boolean
  name: string
  branch: number
  number: number
  branchWinner: IBranchWinner
  handleBranchPlayed: Function
  isFinalist?:boolean
}
// TODO check if props hier so auseinander genommen werden müssen
export const TournamentBranchPlayer = (props: ITournamentBranchPlayer) => {
  const {
    number,
    branch,
    isPlayed,
    name,
    branchWinner,
    handleBranchPlayed,
  } = props
// TODO braucht nicht descturctured werden, wenn nur im hook gebraucht
  const {
    columnStart,
    rowStart,
    handleClick,
    playerResult,
    isWinner,
  } = useTournamentBranchPlayer(
    number,
    branch,
    isPlayed,
    name,
    branchWinner,
    handleBranchPlayed
  )

  return (
    <PlayerContainer
      isPlayed={isPlayed}
      isWinner={isWinner}
      data-name={name}
      columnStart={columnStart}
      rowStart={rowStart}
      onClick={e => handleClick(e)}
      playerResult={playerResult}
    >

      {name}


    </PlayerContainer>
  )
}
