import { keyframes } from "styled-components"

const RotateClockwise = keyframes`

         0% { transform: rotate(0deg); }
         100% { transform: rotate(360deg); }

`
const RotateCounterwise = keyframes`

         0% { transform: rotate(360deg); }
         100% { transform: rotate(0deg); }

`

export { RotateClockwise, RotateCounterwise }
