import styled from "styled-components"
interface IButton {
  marginBottom?: boolean
  marginTop?: boolean
  disabled?: boolean
  gridPosition?: "left" | "center" | "right"
}

export const Button = styled.button<IButton>`
  grid-area: ${({ gridPosition }) =>
    gridPosition !== undefined ? gridPosition : "unset"};
  min-width: 150px;
  max-width: 300px;
  width: 100%;
  font-size: 1rem;
  padding: 1em;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "1.5em" : "0")};
  margin-top: ${({ marginTop }) => (marginTop ? "1.5em" : "0")};
  background-color: transparent;
  /* background-color: ${props =>
    props.disabled === true
      ? props.theme.grey
      : props.theme.kotc_purple.normal}; */
  color: ${({ theme }) => theme.kotc_purple.light};
  border: 1px solid ${({ theme }) => theme.kotc_purple.normal};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? "inherit" : theme.kotc_purple.normal};
    color: ${({ theme, disabled }) => (disabled ? "" : theme.kotc_creme)};
  }
  @media (min-width: 768px) {
    width: auto;
  }
`
interface ITwoButtonContainer {
  gamesWereChosen?: boolean
}

export const TwoButtonsContainer = styled.div<ITwoButtonContainer>`
  display: grid;
  grid-template-columns: ${props =>
    props.gamesWereChosen === true ? "1fr" : "1fr 1fr"};
  grid-column-gap: 10px;
`
