import React from "react"
import { Leaderboard } from "../Leaderboard"
import { Button } from "../Basic"
import {
  DashboadActions,
  DashboadHeader,
  DashboadLeaderboard,
  DashboardLayout,
  DashboadStage,
  DashboadEnd,
} from "./TournamentDashboard.styles"
import { GameStage } from "../GameStage"
import { useTournamentDashboard } from "./useTournamentDashboard"
import { GameIntro } from "../GameIntro"
import { EndScreen } from "../EndScreen"

export const TournamentDashboard = () => {
  const {
    gameIsRunning,
    chooseRandomGame,
    dashboardHeadline,
    gamesLeftToPlay,
    title,
    showEndScreen,
    showWinningScreen,
  } = useTournamentDashboard()
  if (!showEndScreen) {
    return (
      <DashboardLayout>
        <DashboadHeader>
          <h1>{dashboardHeadline}</h1>
        </DashboadHeader>
        <GameIntro title={title} />
        {!gameIsRunning ? (
          <>
            <DashboadActions>
              {gamesLeftToPlay ? (
                <Button onClick={() => chooseRandomGame()} marginTop={true}>
                  Nächstes Spiel
                </Button>
              ) : (
                <p>ALL GAMES ARE PLAYED</p>
              )}
            </DashboadActions>
            <DashboadEnd>
              <Button onClick={() => showWinningScreen()} marginTop={true}>
                Turnier beenden
              </Button>
            </DashboadEnd>
            <DashboadLeaderboard>
              <Leaderboard />
            </DashboadLeaderboard>
          </>
        ) : (
          <DashboadStage>
            <GameStage />
          </DashboadStage>
        )}
      </DashboardLayout>
    )
  } else {
    return <EndScreen />
  }
}
