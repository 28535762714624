import { ISystem } from "../../types"
export interface ISystemSelectionProps {
  systems: ISystem[]
  selectedSystems: string[]
  handleChange: Function
}
export function useSystemSelection(props: ISystemSelectionProps) {
  const { systems, selectedSystems, handleChange } = props
  return { systems, selectedSystems, handleChange }
}
