import {  useState, useContext } from "react"
import { FixturesContext } from "../../../../../../contexts"
export function usePvPPlayer(name: string, isPlayed: boolean) {
  const { setRoundPoints, roundPoints } = useContext(FixturesContext)
  const [isWinner, setIsWinner] = useState(() => false)

  const evalutatePvPFixture = e => {
    if (isPlayed) return

    const { name } = e.target.dataset
    const index = roundPoints.findIndex(obj => obj.name === name)
    const newScore = [...roundPoints]
    newScore[index].score += 1
    setIsWinner(() => true)
    setRoundPoints([...newScore])

  }

  return { handleClick: evalutatePvPFixture, isWinner, name }
}
