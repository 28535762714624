import React from "react"
import { LeaderBoardTable, PlayerColumn } from "./LeaderboardView.styles"
import { Avatar } from "../Avatar"
import { useLeaderboard } from "./useLeaderboard"

export const Leaderboard = () => {
  const { playerdata } = useLeaderboard()
  console.log({ playerdata })
  return (
    <LeaderBoardTable>
      <tbody>
        {playerdata.map((player, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}.</td>
              <td>
                <Avatar {...player.avatar} />
              </td>
              <PlayerColumn>{player.name}</PlayerColumn>
              <td>{player.score}</td>
            </tr>
          )
        })}
      </tbody>
    </LeaderBoardTable>
  )
}
