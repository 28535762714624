import { afterCrown } from "../Basic/Mixins"
export const theme = {
  // colors
  backgroundDark: "#2d2e32",
  backgroundBright: "#f8f8f8",
  grey: "#b8bcbf",
  blue: "#055fdc",
  bluegrey: "#7684aa",
  bluegreyLight: "#DDE0E9",
  red: "#e15639",

  // New Colors
  // primary
  kotc_darkGrey: "#191919",
  kotc_red: {
    normal: "#d65929",
    light: "#F07343",
  },
  kotc_purple: {
    normal: "#804da3",
    light: "#9A67BD",
  },
  kotc_creme: "#fff5ee",
  // secondary
  kotc_yellow: {
    normal: "#f3cf1a",
    light: "#FFE934",
  },
  kotc_orange: {
    normal: "#d99a24",
    light: "#F3B43E",
  },
  kotc_blue: { normal: "#204356", light: "#3A5D70" },
  kotc_lightGrey: "#dedade",
  afterCrown: afterCrown
}
