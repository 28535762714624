import styled from "styled-components"
import {
  FadeOut,
  SlideInLeft,
  SlideInRight,
  SlideInBottom,
  SlideInTop,
  Rotate,
} from "./GameIntro.animations"

interface IWrap {
  isVisible: Boolean
}

const Wrap = styled.div<IWrap>`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.kotc_purple.normal};
  z-index: 5;
  color: ${({ theme }) => theme.kotc_red.normal};
  left: 0;
  /* display: flex; */
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: ${FadeOut} .5s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
`
interface IAnimation {
  animation?: number
}
const Base = styled.div<IAnimation>`
  font-size: 12vw;
  line-height: 1em;
`

const GameNumber = styled(Base)`
  opacity: 0;
  animation: ${({ animation }) =>
      animation === 1
        ? SlideInRight
        : animation === 2
        ? SlideInLeft
        : animation === 3
        ? SlideInTop
        : SlideInBottom}
    ease 1.5s;
  animation-fill-mode: forwards;
`
const Game = styled(Base)`
  opacity: 0;
  /* animation: ${Rotate} 1.5s linear; */
  animation: ${({ animation }) =>
      animation === 1
        ? SlideInBottom
        : animation === 2
        ? SlideInRight
        : animation === 3
        ? SlideInLeft
        : SlideInTop}
    ease 1.5s;
  animation-fill-mode: forwards;
`
export { Wrap, GameNumber, Game }
