import React from "react"
import { useBranch } from "./useBranch"
import { GameDivider } from "./BranchView.styles"
import { TournamentBranchPlayer } from "../TournamentBranchPlayer"
import { Finalist } from "../Finalist"
import { IPlayer } from "../../../../../../types"

interface IBranch {
  number: number
  player: IPlayer[]
}
export const Branch = (props: IBranch) => {
  const { number, player } = props

  const {
    isPlayed,
    handleClick,
    branchWinner,
    handleFinal,
    semiFinalsPlayed,
    finalWinner
  } = useBranch(number, player)

  return (
    <>
      {player.map((p, index) => {
        return (
          <TournamentBranchPlayer
            isPlayed={isPlayed}
            name={p.name}
            branch={number}
            key={index}
            number={index + 1}
            handleBranchPlayed={handleClick}
            branchWinner={branchWinner}
          />
        )
      })}
      <GameDivider number={number} />
      {isPlayed ? (
        <Finalist branchWinner={branchWinner} handleFinal={handleFinal}  finalWinner={ finalWinner}/>
      ) : null}
    </>
  )
}
