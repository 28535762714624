import { TournamentContext } from "../../contexts"
import { useLocalStorage } from "../../hooks"
import { randomCompoundWord } from "random-compound-words"
import { useContext } from "react"
export function useTournamentStart() {
  const { setName } = useContext(TournamentContext)
  const { setStorage, getStorageKeys } = useLocalStorage()
  const { name } = getStorageKeys()

  const handleSubmit = e => {
    e.preventDefault()
    const value = e.target.parentNode.querySelector("input").value
    setStorage(name, value)
    // sets the value of "message" to be "saved in browser storage"
    setName(value)
  }

  const randomValue = randomCompoundWord()

  return {
    handleSubmit,
    randomValue,
  }
}
