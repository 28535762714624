import React from "react"
import { useTwoOnTwoTeam, ITwoOnTwo } from "./useTwoOnTwoTeam"
import { PlayerContainer } from "../../../Basic"

export const TwoOnTwoTeam = (props: ITwoOnTwo) => {
  const { isPlayed, isWinner, team, evaluateScore } = useTwoOnTwoTeam(props)
  if (team.length > 0) {
    return (
      <PlayerContainer
        data-team={`${team[0].name},${team[1].name}`}
        isPlayed={isPlayed}
        onClick={e => evaluateScore(e)}
        isWinner={isWinner}
      >
        {`${team[0].name} und ${team[1].name}`}
      </PlayerContainer>
    )
  }
  return null
}
