import { keyframes } from "styled-components"

const Rotate = keyframes`
  0%      { opacity:0;transform: rotate(0deg) scale(0.1);}
  10%     { opacity:1;transform: rotate(270deg) scale(10);}
  20%     { transform: rotate(360deg) scale(9);}
  30%     { transform: rotate(450deg) scale(8);}
  40%     { transform: rotate(540deg) scale(7);}
  50%     { transform: rotate(630deg) scale(6);}
  60%     { transform: rotate(720deg) scale(5);}
  70%     { transform: rotate(810deg) scale(4);}
  80%     { transform: rotate(900deg) scale(3);}
  90%     { transform: rotate(990deg) scale(2);}
  100.00% { opacity:1;transform: rotate(1080deg) scale(1);}
`

const Rainbow = keyframes`
        0%     { background-color: #ff0000; }
    8.333%     { background-color: #ff8000; }
  16.667%     { background-color: #ffff00; }
  25.000%     { background-color: #80ff00; }
  33.333%     { background-color: #00ff00; }
  41.667%     { background-color: #00ff80; }
  50.000%     { background-color: #00ffff; }
  58.333%     { background-color: #0080ff; }
  66.667%     { background-color: #0000ff; }
  75.000%     { background-color: #8000ff; }
  83.333%     { background-color: #ff00ff; }
  91.667%     { background-color: #ff0080; }
  100.00%     { background-color: #ff0000; }
`

const SlideInRight = keyframes`
  0% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
  opacity: 1;  
  }
`
const SlideInLeft = keyframes`
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;  
  }
`
const SlideInBottom = keyframes`
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;  
  }
`
const SlideInTop = keyframes`
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;  
  }
`

const FadeOut = keyframes`
0%{
  opacity: 1;
}
100%{
  opacity: 0;
}

`

export {
  FadeOut,
  SlideInBottom,
  SlideInTop,
  SlideInRight,
  SlideInLeft,
  Rainbow,
  Rotate,
}
