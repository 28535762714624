import styled from "styled-components"


export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0 20px;

  input {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
`
