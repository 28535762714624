import styled from "styled-components"

interface IPlayerContainer {
  columnStart: string
  rowStart: string
  playerResult?: string
  isPlayed: boolean
  isWinner: boolean
  isFinalist?: boolean
  branch?: Number
  deactivated?: boolean
}

// TODO CLEANUP
const PlayerContainer = styled.div<IPlayerContainer>`
  align-items: center;
  background-color: ${({ isPlayed, isWinner, theme }) =>
    !isPlayed
      ? "transparent"
      : isWinner
      ? theme.kotc_red.normal
      : theme.kotc_red.normal};
  border: 2px solid ${({ theme }) => theme.kotc_red.normal};
  border-top: ${({ isFinalist, branch }) =>
    isFinalist && branch === 1 ? "none" : ""};
  border-bottom: ${({ isFinalist, branch }) =>
    isFinalist && branch === 2 ? "none" : ""};
  color: ${({ isPlayed, theme }) =>
    !isPlayed ? theme.kotc_red.normal : theme.kotc_creme};
  display: flex;
  justify-content: center;

  grid-column-start: ${({ columnStart, isFinalist }) =>
    isFinalist ? "3" : columnStart ? columnStart : "0"};
  grid-column-end: span 2;
  grid-row-start: ${({ rowStart, branch, isFinalist }) =>
    isFinalist && branch === 1
      ? "5"
      : isFinalist
      ? "9"
      : rowStart
      ? rowStart
      : "0"};
  grid-row-end: span 2;

  position: relative;
  text-decoration: ${({ isPlayed, isWinner }) =>
    !isPlayed ? "unset" : isWinner ? "underline" : "line-through"};

  &:hover {
    cursor: ${({ isPlayed, deactivated }) =>
      isPlayed || deactivated ? "not-allowed" : "pointer"};
    background-color: ${({ theme, deactivated }) =>
      deactivated ? "transparent" : theme.kotc_red.normal};
    color: ${({ theme, deactivated }) =>
      deactivated ? theme.kotc_red.normal : theme.kotc_creme};
  }

  &:after {
    display: ${({ isWinner, isPlayed }) =>
      isPlayed && isWinner ? "block" : "none"};
    content: "";
    width: 30px;
    height: 24px;
    background-image: url("/logo_krone.svg");
    position: absolute;
    top: -17px;
    left: -12px;
    background-size: contain;
  }
`

export { PlayerContainer }
