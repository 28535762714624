import styled from "styled-components"

interface ITournamentGrid {
  columns: number
  rows: number
}
const TournamentGrid = styled.div<ITournamentGrid>`
  min-width: 320px;
  display: grid;
  width: 100%;
  height: 70vh;
  grid-template-columns: repeat(${props => props.columns || "6"}, 1fr);
  grid-template-rows: repeat(${props => props.rows || "14"}, 1fr);
`
const SmallFinalTitle = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  grid-row-start: 16;
  text-align: center;
`
// TODO eigenes ELement für den Kram
const VS = styled.div`
  grid-row-start: 17;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 3;
  grid-column-end: span 2;
`

export { TournamentGrid, SmallFinalTitle, VS }
