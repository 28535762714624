import { useState, useEffect, useContext } from "react"
import { ISystem, IGame, IMode } from "../../types"
import { TournamentContext, GamesContext, PlayerContext } from "../../contexts"
import { useLocalStorage, useGameModes } from "../../hooks"

export interface IGamesPreSelection {
  games: IGame[]
  systems: ISystem[]
  modes: IMode[]
}

export function useGamesPreSelection(props: IGamesPreSelection) {
  const { games, systems, modes } = props
  const { setHasGames, setHasPlayers } = useContext(TournamentContext)
  const { setGames } = useContext(GamesContext)
  const { playerCount } = useContext(PlayerContext)

  const {
    setStorage,
    getStorageKeys,
    getStorage,
    issetStorage,
  } = useLocalStorage()
  const { gamesPool: storageGamesPool, games: storageGames } = getStorageKeys()
  const { filterGamesByMode } = useGameModes()

  const [disabled, setDisabled] = useState(true)
  const [modeFilteredGames, setModeFilteredGames] = useState(() =>
    filterGamesByMode(games, modes, playerCount)
  )
  const [selectedGamePool, setSelectedGamePool] = useState(modeFilteredGames)
  const [filteredGamePool, setFilteredGamePool] = useState(null)
  const [allGamesSelected, setAllGamesSelected] = useState(false)
  const [selectedGames, setSelectedGames] = useState(() =>
    issetStorage(storageGames) ? getStorage(storageGames, "object") : []
  )
  const [selectedSystems, setSelectedSystems] = useState(() =>
    systems.map(system => system.title)
  )
  useEffect(() => {
    filterGamesBySystem()
    filterSelectedGamesBySystem()
  }, [selectedSystems])

  const filterSelectedGamesBySystem = () => {
    setSelectedGames(
      selectedGames.filter(game => {
        const filter = game.systems.filter(system =>
          selectedSystems.includes(system.title)
        )
        return filter.length > 0
      })
    )
  }

  const filterGamesBySystem = () => {
    const filteredGames = modeFilteredGames.filter(game => {
      return (
        game.systems.filter(system => selectedSystems.includes(system.title))
          .length > 0
      )
    })
    // @ts-ignore
    if (selectedGamePool !== filterGamesBySystem) {
      setSelectedGamePool(filteredGames)
      setFilteredGamePool(filteredGames)
    }
  }
  const addToSelectedSystems = (title: string) => {
    setSelectedSystems(selectedSystems.concat(title))
  }
  const removeFromSelectedSystems = (title: string) => {
    const newArray = [...selectedSystems]
    const index = newArray.indexOf(title)
    newArray.splice(index, 1)
    if (index > -1) {
      setSelectedSystems(newArray)
    }
  }
  const handleChange = e => {
    if (!e.target.checked) {
      removeFromSelectedSystems(e.target.name)
    } else {
      addToSelectedSystems(e.target.name)
    }
  }

  useEffect(() => {
    setDisabled(!(selectedGames.length > 0))
    setAllGamesSelected(selectedGames === modeFilteredGames ? true : false)
  }, [selectedGames])
  // Games
  const filterGamesByTitle = (title: string) =>
    modeFilteredGames.filter(game => game.title === title)

  const addToSelectedGames = (title: string) => {
    let game = filterGamesByTitle(title)
    setSelectedGames(selectedGames.concat(game))
  }

  const removeFromSelectedGames = (title: string) => {
    const newArray = [...selectedGames]
    const index = newArray.findIndex(game => game.title === title)
    newArray.splice(index, 1)
    if (index > -1) {
      setSelectedGames(newArray)
    }
  }

  const handleGameSelection = (game: IGame) => {
    if (!selectedGames.some(g => g.title === game.title)) {
      addToSelectedGames(game.title)
    } else {
      removeFromSelectedGames(game.title)
    }
  }

  const selectAllGames = () => {
    if (selectedGames !== modeFilteredGames) {
      setSelectedGames(modeFilteredGames)
    } else {
      setSelectedGames([])
    }
  }

  const handleSearch = e => {
    const { value } = e.target

    if (filteredGamePool) {
      setSelectedGamePool(
        filteredGamePool.filter(game =>
          game.title.toUpperCase().includes(value.toUpperCase())
        )
      )
    } else {
      setSelectedGamePool(
        modeFilteredGames.filter(game =>
          game.title.toUpperCase().includes(value.toUpperCase())
        )
      )
    }
  }

  selectedGamePool.sort(function (a, b) {
    const titleB = b.title.toUpperCase()
    const titleA = a.title.toUpperCase()
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    return 0
  })
  const finishSelection = () => {
    setHasGames(true)
    setStorage(storageGames, selectedGames)
    setStorage(storageGamesPool, selectedGames)
    setGames(selectedGames)
  }

  const backToPlayerSelection = () => {
    setHasPlayers(false)
  }

  return {
    systems,
    handleSystemSelection: handleChange,
    selectedSystems,
    selectedGamePool,
    handleGameSelection,
    finishPreSelection: finishSelection,
    disabled,
    handleSearch,
    selectedGames,
    selectAllGames,
    allGamesSelected,
    backToPlayerSelection,
  }
}
