import React from "react"
import ReactDOM from "react-dom"
import {
  ModalClose,
  ModalOverlay,
  ModalStyle,
  ModalWrapper,
  ModalHeader,
  ModalContent,
} from "./Modal.styles"
import { OutsideAlerter } from "../OutsideAlerter"

export const Modal = ({ isShowing, hide, children }) => {

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <ModalOverlay />
          <ModalWrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
            <OutsideAlerter action={hide}>
              <ModalStyle >
                <ModalHeader>
                  <ModalClose
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </ModalClose>
                </ModalHeader>
                <ModalContent>{children}</ModalContent>
              </ModalStyle>
            </OutsideAlerter>
          </ModalWrapper>
        </>,
        document.body
      )
    : null
}
