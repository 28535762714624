import React from "react"
import {
  GamesContainer,
  SearchContainer,
  FilterArea,
} from "./GamesPreSelectionView.styles"
import {
  useGamesPreSelection,
  IGamesPreSelection,
} from "./useGamesPreSelection"
import { SystemSelection } from "../SystemSelection"
import { Game } from "../Game"
import { Button, MobileContainer } from "../Basic"
import { ActionMenu } from "../ActionMenu"
import { CheckBox } from "../CheckBox"

export const GamesPreSelection = (props: IGamesPreSelection) => {
  const {
    systems,
    handleSystemSelection,
    selectedSystems,
    selectedGamePool,
    handleGameSelection,
    finishPreSelection,
    disabled,
    handleSearch,
    selectedGames,
    selectAllGames,
    allGamesSelected,
    backToPlayerSelection,
  } = useGamesPreSelection(props)
  return (
    <MobileContainer>
      {/* TODO eingene Komponente für Filter */}
      <FilterArea>
        <SearchContainer>
          <label>Suche</label>
          <input type="text" onChange={e => handleSearch(e)} />
        </SearchContainer>
        <CheckBox
          text={"Alle Spiele"}
          isSelected={allGamesSelected}
          handleChange={selectAllGames}
        />
        <SystemSelection
          systems={systems}
          handleChange={handleSystemSelection}
          selectedSystems={selectedSystems}
        />
      </FilterArea>
      <GamesContainer>
        {selectedGamePool.map((game, index) => {
          return (
            <Game
              isSelected={selectedGames.some(g => g.title === game.title)!}
              game={game}
              key={index}
              handleGameSelection={handleGameSelection}
            />
          )
        })}
      </GamesContainer>
      <ActionMenu>
        <Button gridPosition="left" onClick={() => backToPlayerSelection()}>
          zurück
        </Button>

        <Button
          gridPosition="right"
          disabled={disabled}
          onClick={() => finishPreSelection()}
        >
          weiter
        </Button>
      </ActionMenu>
    </MobileContainer>
  )
}
