import styled from "styled-components"

export const DashboardLayout = styled.div`
  display: grid;

  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "leaderboard"
    "actions"
    "end"
    "gamestage";

  @media (min-width: 500px) {
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "header header"
      "leaderboard leaderboard"
      "actions end"
      "gamestage gamestage";
  }

  width: 90%;
  @media (min-width: 768px) {
    max-width: 600px;
  }
`

export const DashboadHeader = styled.div`
  text-align: center;
  grid-area: header;
`

export const DashboadEnd = styled.div`
  grid-area: end;
  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const DashboadActions = styled.div`
  grid-area: actions;
  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const DashboadLeaderboard = styled.div`
  grid-area: leaderboard;
`
export const DashboadStage = styled.div`
  grid-area: gamestage;
`
