import React from "react"
import { FixtureWrap } from "../Basic"
import { PartyFixtureHeadline, PartyPlayer } from "./components"
import { useParty } from "./useParty"

export const Party = () => {
  const { setStandings, standings, player } = useParty()
  return (
    <FixtureWrap>
      <PartyFixtureHeadline standings={standings} />
      {player.map((p, index) => {
        return (
          <PartyPlayer
            key={p.name + index}
            name={p.name}
            setStandings={setStandings}
            standings={standings}
          />
        )
      })}
    </FixtureWrap>
  )
}
