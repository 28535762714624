import React from "react"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "../Global"
import { theme } from "../theme"

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <main>{children}</main>
    </ThemeProvider>
  )
}
