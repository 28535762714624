import styled from "styled-components"

export const MobileContainer = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
  @media (min-width: 768px) {
    width: 90%;
    text-align: center;
  }
`
