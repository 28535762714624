import { useState, useEffect } from "react"
import { IBranchWinner} from '../../types'
 export function useTournamentBranchPlayer(
  number: number,
  branch: number,
  isPlayed: boolean,
  name: string,
  branchWinner: IBranchWinner,
  handleBranchPlayed: Function
) {

  const [isWinner, setIsWinner] = useState(false)
  const playerNumber = number + branch * 2

  let columnStart, rowStart, playerResult
  useEffect(() => {
    if (isPlayed) {
      setIsWinner(name === branchWinner.name ? true : false)
      playerResult = name === branchWinner.name ? "winner" : "loser"
    }
  }, [isPlayed])

  switch (playerNumber) {
    case 3:
      columnStart = 1
      rowStart = 1
      break
    case 4:
      columnStart = 5
      rowStart = 1
      break
    case 5:
      columnStart = 1
      rowStart = 13
      break
    case 6:
      columnStart = 5
      rowStart = 13
      break
    default:
      columnStart = 1
      rowStart = 1
  }

  const handleClick = e => {
    const { name } = e.target.dataset
    handleBranchPlayed(name)
  }
  return {
    playerResult,
    columnStart,
    rowStart,
    handleClick,
    isWinner,
    isPlayed,
  }
}
