import React, { useState, createContext, useEffect, useContext } from "react"
export const TournamentModeContext = createContext<any>({})

export const TournamentModeProvider = ({ children }) => {
  const [finalists, setFinalists] = useState(() => [])
  const [smallFinalists, setSmallFinalists] = useState(() => [])
  const [smallFinalistsCount, setSmallFinalistsCount] = useState(() => 0)
  const [finalistsCount, setFinalistsCount] = useState(0)
  const [finalIsPlayed, setFinalIsPlayed] = useState(false)
  const [semiFinalsPlayed, setSemiFinalsPlayed] = useState(false)
  const [smallFinalsPlayed, setSmallFinalsPlayed] = useState(false)
  const [rankings, setRankings] = useState([])
  const [test, setTest] = useState(null)
  const [finalIsDeacticvated, setFinalIsDeactivated] = useState(true)

  useEffect(() => {
    if (smallFinalsPlayed) {
      setFinalIsDeactivated(false)
    }
  }, [smallFinalsPlayed])

  useEffect(() => {
    setSmallFinalistsCount(smallFinalists.length)
  }, [smallFinalists])
  useEffect(() => {
    if (finalistsCount === 2) setSemiFinalsPlayed(true)
  }, [finalistsCount])

  useEffect(() => {
    if (finalistsCount <= 2) setFinalistsCount(finalists.length)
  }, [finalists])


  return (
    <TournamentModeContext.Provider
      value={{
        test,
        setTest,
        finalists,
        setFinalists,
        smallFinalists,
        setSmallFinalists,
        finalistsCount,
        smallFinalsPlayed,
        semiFinalsPlayed,
        setRankings,
        smallFinalistsCount,
        setSmallFinalsPlayed,
        setFinalIsPlayed,
        finalIsPlayed,
        finalIsDeacticvated
      }}
    >
      {children}
    </TournamentModeContext.Provider>
  )
}
