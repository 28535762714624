function afterCrown(isPlayed: boolean, isWinner: boolean) {

  const display = isPlayed && isWinner ? "block" : "none"
  return `
  &:after {
    display: ${display}
    content: "";
    width: 20px;
    height: 16px;
    background-image: url("/logo_krone.svg");
    position: absolute;
    top: -12px;
    left: -3px;
    background-size: contain;
  }
  `
}

export { afterCrown }
