import React from "react"
import { usePvPPlayer } from "./usePvPPlayer"
import { PlayerContainer } from "../../../Basic"

interface IPvPPlayer {
  name: string
  isPlayed: boolean
}
export const PvPPlayer = (props: IPvPPlayer) => {
  const { name, isPlayed } = props
  const { isWinner, handleClick } = usePvPPlayer(name, isPlayed)

  return (
    <PlayerContainer
      isWinner={isWinner}
      isPlayed={isPlayed}
      onClick={e => {
        handleClick(e)
      }}
      data-name={name}
    >
      {name}
    </PlayerContainer>
  )
}
