import { createGlobalStyle } from "styled-components"
// @ts-ignore
import cooperbold from "../../fonts/CooperHewitt-Bold.ttf"
// @ts-ignore
import cooperbook from "../../fonts/CooperHewitt-Book.ttf"

export const GlobalStyles = createGlobalStyle`


@font-face {
    font-family: 'Cooper Hewitt';
    src: url(${cooperbold}) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cooper Hewitt Book';
    src: url(${cooperbook}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    box-sizing: border-box;
    font-family: 'Cooper Hewitt Book';
}

h1, h2, h3, h4 {
    font-family: 'Cooper Hewitt';
}
html {
    height: 100%;
    line-height: 1.3em;
}

body {
    margin: 0;
   background-color: ${({theme})=>theme.kotc_darkGrey};
   color: ${({theme})=>theme.kotc_creme};
}
label, form, input {
    max-width: 320px;
}

button {
    border: none;
    border-radius: 50px;
}
 input {
     border-radius: 8px;
 }

tr, td {


} 

legend { 
    font-size: 1.3rem;
}

fieldset {
    padding: 1.5rem;
}


`
