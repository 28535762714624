import { useContext } from "react"
import {
  TournamentContext,
  PlayerContext,
  GamesContext,
  GameStageContext,
} from "../../contexts"

export function useRoundUp() {
  const { name, setHasGames, setRoundUpFinished } = useContext(
    TournamentContext
  )
  const { player } = useContext(PlayerContext)
  const { games } = useContext(GamesContext)
  const { chooseRandomGame } = useContext(GameStageContext)


  const backToGameSelection = () => {
    setHasGames(false)
  }

  const startTournament = () => {
    setRoundUpFinished(true)
    chooseRandomGame()
  }

  return {
    name,
    player,
    games,
    backToGameSelection,
    startTournament,
  }
}
