import React from "react"
import { useAvatar, IAvatar } from "./useAvatar"
import { Image, Button, Wrapper } from "./Avatar.styles"
// @ts-ignore
import Redo from "./assets/redo.inline.svg"

export const Avatar = (props: IAvatar) => {
  const { url, name, size, clickHandler } = useAvatar(props)

  return (
    <Wrapper>
      <Image src={url} alt={name} size={size} />
      {clickHandler ? (
        <Button onClick={() => clickHandler()}>
          <Redo />
        </Button>
      ) : null}
    </Wrapper>
  )
}
