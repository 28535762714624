import styled from "styled-components"
import { FixtureWrap } from "../Basic"
export const TwoOnTwoWrap = styled(FixtureWrap)`
  display: grid;
  justify-content: center;
  text-align: center;

  span {
    padding: 1em;
  }
`
