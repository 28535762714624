import styled from "styled-components"

interface ISection {
  top?: boolean
}
export const Section = styled.section<ISection>`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  /* justify-content: center; */
  max-width: 100vw;
  width: 100%;
  @media (max-width: 768px) {
    text-align: center;
    justify-content: ${({ top }) => (top ? "flex-start" : "center")};
  }
`
