export interface IAvatar {
  name: string
  url: string
  size?: Size
  clickHandler?: Function
}

export type Size = "small" | "big" | "full";
export function useAvatar(props: IAvatar) {
  const { name, url, size, clickHandler } = props

  return { name, url, size, clickHandler }
}
