import { useContext } from "react"
import {
  TournamentContext,
  GameStageContext,
  GamesContext,
  PlayerContext,
} from "../../contexts"
import { useLocalStorage } from "../../hooks"

export function useTournamentStop() {
  const { finishTournament } = useContext(TournamentContext)
  const { resetGames } = useContext(GamesContext)
  const { resetPlayer } = useContext(PlayerContext)
  const { resetGameStage } = useContext(GameStageContext)

  const { clearStorage } = useLocalStorage()

  const stopTournament = () => {
    resetGames()
    resetPlayer()
    resetGameStage()
    finishTournament()
    clearStorage()
    // clear Localstorage
    // set Running false
    // save in Database ... LATER
  }

  return { handleClick: stopTournament }
}
