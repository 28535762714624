import React from "react"
import { IFinalist } from "../../types"
import { useFinalist } from "./useFinalist"
import { PlayerContainer } from "../TournamentBranchPlayer/TournamentBranchPlayerView.styles"

export const Finalist = (props: IFinalist) => {
  const {
    handleClick,
    rowStart,
    columnStart,
    name,
    branch,
    isPlayed,
    isWinner,
    finalIsDeacticvated,
  } = useFinalist(props)
  return (
    <PlayerContainer
      data-name={name}
      columnStart={columnStart}
      rowStart={rowStart}
      isPlayed={isPlayed}
      isWinner={isWinner}
      onClick={e => handleClick(e)}
      isFinalist={true}
      branch={branch}
      deactivated={finalIsDeacticvated}
    >
      {name}
    </PlayerContainer>
  )
}
