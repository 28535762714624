import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.kotc_darkGrey};
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "left center  right";
  justify-content: center;
  align-items: center;
  padding: 1em 10px;
  border-top: 1px dotted ${({ theme }) => theme.kotc_purple.normal};
  z-index: 3;
  left: 0;
  width: 100%;
  grid-column-gap: 10px;


  /* div:nth-child(1) {
    grid-area: left;
    display: flex;
    justify-content: flex-start;
  }

  div:nth-child(2) {
    grid-area: right;
    display: flex;
    justify-content: flex-end;
  } */
  @media (min-width: 768px) {
    border-top: 0;
    position: relative;
    max-width: 600px;
    margin: 0 auto;

  }
`
