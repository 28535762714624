import React from "react"
import { useEndScreen } from "./useEndScreen"
import { Avatar } from "../Avatar"
import {
  Wrap,
  Ribbon,
  BackgroundBeams,
  SVGWrap,
  BottomMenu,
} from "./EndScreen.styles"
import { TournamentStop } from "../TournamentStop"
// @ts-ignore
import Crown from "./assets/logo_krone.inline.svg"

interface IEndScreen {}
export const EndScreen = (props: IEndScreen) => {
  const { scrollHeight, playerdata, winner, url, name } = useEndScreen()
  console.log({ scrollHeight }, { playerdata }, { winner }, { name }, { url })

  return (
    <Wrap>
      <SVGWrap>
        <BackgroundBeams />
      </SVGWrap>
      <div>
        <Crown />
        {url ? <Avatar size="full" url={url} name={name} /> : null}
        <Ribbon>
          <strong>{name ? <span>{name}</span> : null}</strong>
        </Ribbon>
      </div>
      <BottomMenu>
        <TournamentStop />
      </BottomMenu>
    </Wrap>
  )
}
