import { useState, useEffect, useContext } from "react"
import { PlayerContext, FixturesContext } from "../../../../contexts"
import { shuffle } from "../../../../utils"
export function usePvP() {
  const { roundsToPlay, currentGame } = useContext(FixturesContext)
  const { player, playerCount } = useContext(PlayerContext)
  const [rounds, setRounds] = useState([])

  const initMatches = []
  for (let i = 0; i < roundsToPlay; i++) {
    for (let k = i + 1; k < playerCount; k++) {
      initMatches.push([player[i], player[k]])
    }
  }

  useEffect(() => {
    setRounds(shuffle(initMatches))
  }, [currentGame, roundsToPlay])

  return { rounds }
}
