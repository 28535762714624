import styled from "styled-components"

interface IGameDivider {
  number: number
}
const GameDivider = styled.div<IGameDivider>`
  grid-column-start: 2;
  grid-column-end: span 4;
  grid-row-start: ${props => (props.number === 1 ? 3 : 11)};
  grid-row-end: span 2;
  border: 2px solid ${({ theme }) => theme.kotc_red.normal};
  border-top: ${props => (props.number === 1 ? "none" : "")};
  border-bottom: ${props => (props.number === 2 ? "none" : "")};
`

export { GameDivider }
