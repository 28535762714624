import React from "react"
import { CheckboxContainer } from "./CheckBox.styles"

interface ICheckbox {
  handleChange: Function
  isSelected: boolean
  text: string
}

export const CheckBox = (props: ICheckbox) => {
  const { handleChange, isSelected, text } = props
  return (
    <CheckboxContainer>
      <input
        type="checkbox"
        id={text}
        name={text}
        onChange={e => handleChange(e)}
        checked={isSelected}
      />
      <label htmlFor={text}>{text}</label>
    </CheckboxContainer>
  )
}
