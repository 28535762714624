import React from "react"
import { TournamentGrid, SmallFinalTitle, VS } from "./TournamentVIew.styles"
import { Branch, SmallFinalist } from "./components"
import { useTournament } from "./useTournament"
export const Tournament = () => {
  const {
    rows,
    columns,
    branches,
    semiFinalsPlayed,
    smallFinalists,
    smallFinalistsCount,
  } = useTournament()
  return (
    <TournamentGrid rows={rows} columns={columns}>
      {branches.map((branch, index) => {
        return <Branch player={branch} key={index} number={index + 1} />
      })}

      {semiFinalsPlayed ? (
        <>
          <SmallFinalTitle>Spiel um Platz 3</SmallFinalTitle>
          <VS>vs.</VS>
          {smallFinalistsCount > 0 &&
            smallFinalists.map((player, index) => {
              return (
                <SmallFinalist number={index} player={player} key={index} />
              )
            })}
        </>
      ) : null}
    </TournamentGrid>
  )
}
