import styled from "styled-components"
import { Size } from "./useAvatar"
interface ImageProps {
  size?: Size
}

const Wrapper = styled.div`
  position: relative;
`
const Image = styled.img<ImageProps>`
  height: ${({ size }) =>
    size && size === "big" ? "192px" : size === "full" ? "100%" : "64px"};
  border-radius: 50%;

  background: rgb(214, 89, 41);
  background: linear-gradient(
    -8deg,
    rgba(214, 89, 41, 1) 0%,
    rgba(214, 89, 41, 1) 13%,
    rgba(192, 108, 135, 1) 34%,
    rgba(128, 77, 163, 1) 74%,
    rgba(128, 77, 163, 1) 100%
  );
`

const Button = styled.button`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0px;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.kotc_darkGrey};
  border: 2px solid ${({ theme }) => theme.kotc_purple.normal};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: ${({ theme }) => theme.kotc_red.normal};
    fill: ${({ theme }) => theme.kotc_red.normal};
    height: 24px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.kotc_purple.normal};
  }
`
export { Image, Button, Wrapper }
