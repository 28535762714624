import { useState, useEffect } from "react"

export function usePartyFixtureHeadline(standings: number) {
  const [headlineText, setHeadlineText] = useState('Wer hat gewonnen?')

  useEffect(() => {
    if (standings) {
      setHeadlineText(getHeadlineText(standings))
    }
  }, [standings])

  return { headlineText }
}

function getHeadlineText(number) {
  switch (number) {
    case 0:
      return "Wer hat gewonnen?"
    case 1:
      return "Wer ist zweiter?"
    case 2:
      return "Wer ist dritter?"
    default:
      return "Alle Punkte sind vergeben"
  }
}
