import styled from "styled-components"
// @ts-ignore
import LogoSVG from "../../images/kotc_logo/svg/logo.inline.svg"

export const LogoImg = styled(LogoSVG)``

export const LogoWrap = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  ${LogoImg} {
    margin: 0.5em;
    max-height: 90px;
    max-width: 320px;
  }
  @media (min-width: 768px) {
    height: 25vh;
    ${LogoImg} {
      max-height: 160px;
      margin: 1em 0;
      max-width: 320px;
    }
  }
`
