import styled from "styled-components"
import { RotateClockwise } from "./EndScreen.animations"
// @ts-ignore
import Beams from "./assets/kotcstrahlen_winner.inline.svg"

const BottomMenu = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.kotc_darkGrey};
  max-width: 100%;
  padding: 1em 10px;
  border-top: 1px dotted ${({ theme }) => theme.kotc_red.normal};
  z-index: 3;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  > button:first-child {
    margin-right: 10px;
  }
`
const BackgroundBeams = styled(Beams)`
  position: absolute;
  z-index: -1;
  animation: ${RotateClockwise} 30s infinite linear;
  .st0 {
    display: block;
  }
`

const SVGWrap = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  svg {
    width: 300vw;
    height: 300vh;
  }
`

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  height: calc(100vh - 325.75px);
  /* background-color: ${({ theme }) => theme.kotc_yellow.normal}; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > div:nth-child(2) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      height: auto;
      max-width: 220px;
      -webkit-transform: rotate(25deg);
      transform: rotate(31deg);
      margin-block-end: -27%;
      z-index: 4;
      margin-right: -46px;
      .st7 {
        fill: ${({ theme }) => theme.kotc_yellow.normal};
      }
    }

    span {
      color: ${({ theme }) => theme.kotc_red.normal};

      font-size: 2em;
      line-height: 1em;
      max-width: 95vw;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  @media (max-width: 769px) {
    height: calc(100vh - 194px);
    align-items: flex-start;


  }
`

const Ribbon = styled.h2`
  font-size: 16px !important;
  /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */

  position: relative;
  background: ${({ theme }) => theme.kotc_purple.normal};
  color: #fff;
  text-align: center;
  padding: 1em 2em; /* Adjust to suit */
  margin: 1rem auto; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: -1em;
    border: 1.5em solid ${({ theme }) => theme.kotc_purple.light};
    z-index: -1;
  }
  &:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
  }
  &:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
  }
  strong:before,
  strong:after {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #804f7c transparent transparent transparent;
    bottom: -1em;
  }
  strong:before {
    left: 0;
    border-width: 1em 0 0 1em;
  }
  strong:after {
    right: 0;
    border-width: 1em 1em 0 0;
  }
`

export { Wrap, Ribbon, BackgroundBeams, SVGWrap, BottomMenu }
