import { useState, useContext } from "react"
import { FixturesContext } from "../../../../../../contexts"
export function usePartyPlayer(standings: number, setStandings: Function) {
  const [clicked, setClicked] = useState(false)
  // rank ist für css
  const [rank, setRank] = useState(() => 0)
  const { setRoundPlayed, setRoundPoints, roundPoints } = useContext(FixturesContext)

  const handleClick = e => {
    if (clicked || standings === 3) return
    const { name } = e.target.dataset
    const newScore = roundPoints.filter((obj) => obj.name !== name)

    setRoundPlayed()
    setClicked(true)
    setStandings(prev => prev + 1)
    setRank(standings + 1)
    switch (standings) {
      case 0:
        setRoundPoints([...newScore, {name, score: 3}])
        break
        case 1:
        setRoundPoints([...newScore, {name, score: 2}])
        break
        case 2:
        setRoundPoints([...newScore, {name, score: 1}])
        break
      default:
        return
    }
    // TODO: begrenzt die maximale Zahl an Punkten
  }

  return {
    rank,
    handleClick,
  }
}
