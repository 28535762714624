import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useApiTransform } from "../hooks"
import {
  Layout,
  Section,
  GamesPreSelection,
  Button,
  PlayerChoice,
  TournamentStart,
  TournamentDashboard,
  Logo,
  RoundUp
} from "../components"
import { TournamentContext } from "../contexts"
import SEO from "../components/seo"
const IndexPage = ({ data }) => {
  const [fetchedGames, setFetchedGames] = useState(null)
  const games = data.allGamesJson.nodes
  const systems = data.allSystemsJson.nodes
  const modes = data.allModesJson.nodes
  const {
    isStarted,
    setIsStarted,
    isRunning,
    hasPlayers,
    hasGames,
    hasName,
  } = useContext(TournamentContext)
  useEffect(() => {
    fetch(`http://localhost:8088/api/v1/games/test`)
      .then(response => {
        if (response.ok) return response.json()
        else throw "Api nicht erreichbar"
      })
      .then(resultData => {
        console.log(useApiTransform(resultData))
      })
      .catch(err => console.warn({ err }))
  }, [])

  function useData(games) {
    return {
      games,
      systems,
      modes,
    }
  }

  const startTournament = () => {
    setIsStarted(true)
  }
  return (
    <Layout>
      <SEO title="King of the couch" />
      <div>
        <Logo />
      </div>
      <Section top={hasPlayers}>
        {!isStarted ? (
          <>
            <Button onClick={() => startTournament()}>New Tournament</Button>
          </>
        ) : !hasName ? (
          <TournamentStart />
        ) : !hasPlayers ? (
          <PlayerChoice />
        ) : !hasGames ? (
          <GamesPreSelection
            {...useData(fetchedGames ? fetchedGames : games)}
          />
        ) : !isRunning ? <RoundUp />: null}
        {isRunning ? <TournamentDashboard /> : null}
      </Section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query preSelectionQuery {
    allSystemsJson {
      nodes {
        title
      }
    }
    allGamesJson {
      # allGamesJson(filter: {modes: {elemMatch: {title: {eq: "PvP"}}}}) {
      nodes {
        title
        systems {
          title
        }
        modes {
          title
        }
      }
    }
    allModesJson {
      nodes {
        title
        rules
        min_players
      }
    }
  }
`
