import styled from "styled-components"
interface IGameContainerProps {
  selected?: boolean
}

export const GameContainer = styled.div<IGameContainerProps>`
  border-top: 1px solid ${({ theme }) => theme.kotc_red.normal};
  background-color: transparent;
  display: block;
  position: relative;

  input:checked ~ label {
    color: ${({ theme }) => theme.kotc_creme};

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: ${({ theme }) => theme.kotc_purple.normal};
      border-color: ${({ theme }) => theme.kotc_purple.normal};
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    border: 1px solid ${({ theme }) => theme.kotc_red.normal};
  }
`
export const Title = styled.label<IGameContainerProps>`
  color: ${({ theme }) => theme.kotc_red.normal};
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  max-width: 100%;
  &:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    background-color: ${({ theme }) => theme.kotc_red.normal};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
  }

  &:after {
    width: 32px;
    height: 32px;
    content: "";
    border: 1px solid ${({ theme }) => theme.kotc_red.normal};
    background-color: transparent;
    background-image: ${({ selected }) =>
      selected
        ? `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ")`
        : ""};
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
  }

  @media (min-width: 768px) {
    height: 3em;
    display: flex;
    align-items: center;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 74%;
    }
  }
`
