import React from "react"
import { Wrap, GameNumber, Game } from "./GameIntro.styles"
import { useGameIntro, IGameIntro } from "./useGameIntro"

export const GameIntro = (props: IGameIntro) => {
  const { isVisible, title, gameNumber, gameNumberAnimation } = useGameIntro(
    props
  )

  return (
    <Wrap isVisible={isVisible}>
      <div>
        <GameNumber animation={gameNumberAnimation}>
          <strong>SPIEL {gameNumber}</strong>
        </GameNumber>
        <Game animation={gameNumberAnimation}>{title}</Game>
      </div>
    </Wrap>
  )
}
