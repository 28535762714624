import React from "react"
import { useRoundUp } from "./useRoundUp"
import { Button, Table, MobileContainer } from "../Basic"
import { ActionMenu } from "../ActionMenu"
export const RoundUp = () => {
  const {
    name,
    player,
    games,
    backToGameSelection,
    startTournament,
  } = useRoundUp()
  return (
    <MobileContainer>
      <h2> {name}</h2>
      <Table>
        <thead>
          <tr>
            <td>Mitspieler</td>
          </tr>
        </thead>
        <tbody>
          {player.map((p, index) => (
            <tr key={p.name + index}>
              <td>{p.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Table>
        <thead>
          <tr>
            <td>Ausgewählte Spiele</td>
          </tr>
        </thead>
        <tbody>
          {games.map((game, index) => (
            <tr key={game.title + index}>
              <td>{game.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ActionMenu>
        <Button onClick={() => backToGameSelection()} gridPosition="left">
          zurück
        </Button>
        <Button onClick={() => startTournament()} gridPosition="right">
          lets go
        </Button>
      </ActionMenu>
    </MobileContainer>
  )
}
