import React from "react"
import { usePvPRound } from "./usePvPRound"
import { PvPRoundContainer } from "./PvPRoundView.styles"
import { PvPPlayer } from "../PvPPlayer"
interface IPvPRound {
  roundUp: any
}
export const PvPRound = (props: IPvPRound) => {
  const { roundUp } = props

  const {  handleRoundPlayed, isPlayed } = usePvPRound()
  return (
    <PvPRoundContainer  onClick={e => handleRoundPlayed(e)}>
      <PvPPlayer isPlayed={isPlayed} name={roundUp[0].name} />
      {/* TODO change to own element */}
      <span style={{ textAlign: "center" }}>vs.</span>
      <PvPPlayer isPlayed={isPlayed} name={roundUp[1].name} />
    </PvPRoundContainer>
  )
}
