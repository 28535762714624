import styled from "styled-components"

const GamesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  max-width: 90%;
  justify-content: center;
  margin: 0 auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    max-width: 90%;
  }
`

const FilterArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1em;

  max-width: 400px;
  margin: 0 auto;
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export { GamesContainer, FilterArea, SearchContainer }
