import { useState } from "react"
import { IGame } from "../../types"
interface IGameViewProps {
  game: IGame
  handleGameSelection: Function
  isSelected: boolean
}

export function useGame(props: IGameViewProps) {
  const { game, handleGameSelection, isSelected } = props

  const handleClick = () => {
    handleGameSelection(game, !isSelected)
  }

  return {
    isSelected,
    handleClick,
    title: game.title,
  }
}
