import React from "react"
import { Button, Input } from "../Basic"
import { useTournamentStart } from "./useTournamentStart"

export const TournamentStart = () => {
  const { randomValue, handleSubmit } = useTournamentStart()
  return (
    <>
      <label>Name des Turniers</label>
      <Input defaultValue={randomValue}></Input>
      <Button type="submit" marginTop={true} onClick={e => handleSubmit(e)}>
        ok
      </Button>
    </>
  )
}
