import { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TournamentContext, PlayerContext } from "../../contexts"
import { useLocalStorage } from "../../hooks"
import { useModal } from "../../hooks"
import { randomIndex } from "../../utils"

export function usePlayerChoice() {
  const { allFile: nodes } = useStaticQuery(
    graphql`
      query AvatarQuery {
        allFile(filter: { sourceInstanceName: { eq: "avatars" } }) {
          nodes {
            name
            publicURL
          }
        }
      }
    `
  )
  const [avatars, setAvatars] = useState(nodes.nodes ?? null)

  const [usedAvatars, setUsedAvatars] = useState(null)
  const [avatarsCount, setAvatarsCount] = useState(avatars.length)
  const { setStorage, getStorageKeys } = useLocalStorage()
  const { isShowing, toggle } = useModal()
  const [disabled, setDisabled] = useState(() => true)
  const [error, setError] = useState({ hasError: false, text: "" })
  const { setHasPlayers } = useContext(TournamentContext)
  const { player, setPlayer } = useContext(PlayerContext)
  const [playerCount, setPlayerCount] = useState(player.length)
  const [reachedMinimalPlayers, setReachedMinimalPlayers] = useState(
    () => false
  )
  const MINIMAL_PLAYERS = 3
  const { player: storagePlayer } = getStorageKeys()
  useEffect(() => {
    if (playerCount >= MINIMAL_PLAYERS) {
      setReachedMinimalPlayers(true)
    } else if (playerCount < MINIMAL_PLAYERS) {
      setReachedMinimalPlayers(false)
    }
  }, [playerCount])

  useEffect(() => {
    if (avatars) setAvatarsCount(avatars.length)
  }, [avatars])


  const handleClick = (e, action: string = "add") => {
    e.preventDefault()
    let value = ""
    if (action === "add") {
      value = e.target.parentNode.querySelector("input").value
      if (value === "") {
        setError({ hasError: true, text: "Der Name darf nicht leer sein." })
        return
      }
      if (player.find(p => p.name === value)) {
        setError({ hasError: true, text: "Der Name ist schon vorhanden." })
        return
      }
      let obj = {}
      obj["name"] = e.target.parentNode.querySelector("input").value
      obj["avatar"] = randomAvatar
      addToUsedAvatars(randomAvatar)
      let newPlayer = player.concat(obj)
      setPlayer(newPlayer)
      setPlayerCount(playerCount + 1)

      e.target.parentNode.querySelector("input").value = ""
      setPlayerCount(playerCount + 1)
      setError({ hasError: false, text: "" })
      toggleModal()
      return
    }

    value = e.target.parentNode.parentNode.children[1].outerText

    const currentPlayer = player.find(p => p.name === value);
    const newPlayer = player.filter(p => p.name !== value)
    const newAvatars = usedAvatars.filter(
      avatar => avatar !== currentPlayer.avatar.name
    )
    setUsedAvatars(newAvatars)
    setPlayer(newPlayer)
    setPlayerCount(playerCount - 1)
    setError({ hasError: false, text: "" })
  }

  const handleKeyUp = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleClick(e, "add")
    }
  }
  useEffect(() => {
    setRandomAvatar(chooseRandomAvatar())
  }, [usedAvatars])
  useEffect(() => {
    if (reachedMinimalPlayers) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [reachedMinimalPlayers, player])

  const finishPlayerChoice = () => {
    setStorage(storagePlayer, player)
    setHasPlayers(true)
  }

  const toggleModal = () => {
    toggle()
  }

  const addToUsedAvatars = avatar => {
    if (usedAvatars) {
      setUsedAvatars(prev => [...prev, avatar.name])
    } else {
      setUsedAvatars([avatar.name])
    }
  }

  const chooseRandomAvatar = () => {
    let rdmIndx = randomIndex(avatarsCount)
    if (usedAvatars) {
      do {
        rdmIndx = randomIndex(avatarsCount)
      } while (usedAvatars.includes(avatars[rdmIndx].name))
    }
    const avatar = avatars[rdmIndx]

    return {
      name: avatar.name,
      url: avatar.publicURL,
    }
  }
  const [randomAvatar, setRandomAvatar] = useState(chooseRandomAvatar())

  const newAvatar = () => {
    setRandomAvatar(chooseRandomAvatar())
  }

  const disableText =
    playerCount === 0
      ? `Es müssen aktuell mindestens ${MINIMAL_PLAYERS} Spieler sein`
      : `Noch mindestens ${MINIMAL_PLAYERS - playerCount} Spieler`

  const placeholderText = `Spieler ${playerCount + 1}`

  return {
    player,
    handleClick,
    reachedMinimalPlayers,
    finishPlayerChoice,
    disabled,
    error,
    disableText,
    placeholderText,
    toggleModal,
    playerCount,
    isShowing,
    handleKeyUp,
    newAvatar,
    randomAvatar,
  }
}
