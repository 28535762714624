import { useContext, useState, useEffect } from "react"
import { TournamentModeContext } from "../../context"
import { FixturesContext } from "../../../../../../contexts"

export function useSmallFinalist(number: number, player: any) {
  const [isPlayed, setIsPlayed] = useState(false)
  const [isWinner, setIsWinner] = useState(false)
  const [smallFinalsPlayerResult, setSmallFinalsPlayerResult] = useState(null)
  const [smallFinalsWinner, setSmallFinalsWinner] = useState(null)
  const [smallFinalsLoser, setSmallFinalsLoser] = useState(null)
  const {
    setSmallFinalsPlayed,
    setRankings,
    smallFinalists,
    smallFinalsPlayed,
  } = useContext(TournamentModeContext)
  const { setRoundPlayed, roundPoints, setRoundPoints } = useContext(FixturesContext)

  useEffect(() => {
    if (smallFinalsPlayed)
      setSmallFinalsPlayerResult(
        smallFinalsWinner === player.name ? "winner" : "loser"
      )
  }, [smallFinalsPlayed])

  const handleClick = e => {
    if (smallFinalsPlayed) return
    setRoundPlayed()
    const { name } = e.target.dataset
    const newScore = roundPoints.filter(player => player.name !== name)
    setRoundPoints([...newScore, {name, score: 1}])
    setSmallFinalsWinner(name)
    setSmallFinalsPlayed(true)
  }
  useEffect(() => {
    if (smallFinalsPlayed) {
      setIsPlayed(true)
      setIsWinner(smallFinalsWinner === player.name ? true : false);
    }
  }, [smallFinalsPlayed])

  useEffect(() => {
    if (smallFinalsLoser) {
      const element = { name: smallFinalsLoser, score: 0 }
      setRankings(prev => [...prev, element])
    }
  }, [smallFinalsLoser])

  return { smallFinalsPlayerResult, handleClick, isPlayed, isWinner }
}

export const identifyLoser = (array, winner) => {
  return array.find(element => element.name !== winner.name)
}
