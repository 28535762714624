import { useContext, useState, useEffect } from "react"
import { PlayerContext } from "../../../../contexts"
import { shuffle } from "../../../../utils"
import { TournamentModeContext } from "./context"

export function useTournament() {
  const { playerCount, player } = useContext(PlayerContext)
  // TODO more flexible
  const columns = playerCount === 4 ? 6 : 15
  const rows = 18
  const [branches, setBranches] = useState(() => [])
  const {
    setSmallFinalsPlayed,
    semiFinalsPlayed,
    smallFinalists,
    smallFinalistsCount,
  } = useContext(TournamentModeContext)
  // useEffect(() => {
  //   if (isFinalist.length === 2) {
  //     setSemiFinalsPlayed(true)
  //   }
  // }, [isFinalist])

  useEffect(() => {
    shuffle(player)
    setBranches([
      [player[0], player[1]],
      [player[2], player[3]],
    ])
  }, [player])


  return {
    rows,
    columns,
    branches,
    semiFinalsPlayed,
    smallFinalists,
    smallFinalistsCount,
    setSmallFinalsPlayed
  }
}
