import styled from "styled-components"

interface ISmallFinalists {
  index: number
  smallFinalsResult: string
  isPlayed: boolean
  isWinner: boolean
}
const SmallFinalists = styled.div<ISmallFinalists>`
cursor: pointer;
display: flex;
align-items: center;
background-color: ${({ isPlayed, isWinner, theme }) =>
    !isPlayed
      ? "transparent"
      : isWinner
      ? theme.kotc_red.normal
      : theme.kotc_red.normal};
border: 1px solid ${({ theme }) => theme.kotc_red.normal};

  justify-content: center;
  grid-column-start: ${({ index }) => (index === 1 ? 5 : 1)};
  grid-column-end: span 2;
  grid-row-start: 17;
  font-weight: ${props =>
    props.smallFinalsResult === "winner" ? "700" : "300"};
  text-decoration: ${props =>
  props.smallFinalsResult === "loser" ? "line-through" : "unset"};
    

    position: relative;
  text-decoration: ${({ isPlayed, isWinner }) =>
    !isPlayed ? "unset" : isWinner ? "underline" : "line-through"};

color: ${({ isPlayed, theme }) =>
    !isPlayed ? theme.kotc_red.normal : theme.kotc_creme};

  &:hover {
    cursor: ${({ isPlayed }) => (isPlayed ? "not-allowed" : "pointer")};
    background-color: ${({ theme }) => theme.kotc_red.normal};
    color: ${({ theme }) => theme.kotc_creme};
  }

  &:after {
    display: ${({ isWinner, isPlayed }) =>
      isPlayed && isWinner ? "block" : "none"};
    content: "";
    width: 30px;
    height: 24px;
    background-image: url("/logo_krone.svg");
    position: absolute;
    top: -17px;
    left: -12px;
    background-size: contain;
  }
`

export { SmallFinalists }
