import { useContext } from "react"

import { GameStageContext, FixturesContext } from "../../contexts"

export function useGameStage() {
  const { finishRound } = useContext(GameStageContext)

  const { allRoundsPlayed, roundPoints } = useContext(FixturesContext)

  const handleClick = () => {
    finishRound(roundPoints)
  }

  return { allRoundsPlayed, handleClick }
}
