import React from "react"
import { PartyPlayerContainer } from "./PartyPlayerView.styles"
import { usePartyPlayer } from "./usePartyPlayer"
interface IPartyPlayer {
  name: string
  standings: number
  setStandings: Function
}
export const PartyPlayer = (props: IPartyPlayer) => {
  const { name, standings, setStandings } = props
  const { rank, handleClick } = usePartyPlayer(
    standings,
    setStandings
  )
  return (
    <PartyPlayerContainer
      onClick={(e) => handleClick(e)}
      standings={standings}
      rank={rank}
      data-name={name}
    >
      {name}
    </PartyPlayerContainer>
  )
}
