import React from "react"
import { usePlayerChoice } from "./usePlayerChoice"
import { Button, Input, Table, InputWrap } from "../Basic"
import { ErrorText } from "../ErrorText"
import { Modal } from "../Modal"
import { ActionMenu } from "../ActionMenu"
import { OutsideAlerter } from "../OutsideAlerter"
import { Avatar } from "../Avatar"

export const PlayerChoice = () => {
  const {
    player,
    handleClick,
    reachedMinimalPlayers,
    error,
    finishPlayerChoice,
    disableText,
    disabled,
    placeholderText,
    toggleModal,
    isShowing,
    playerCount,
    handleKeyUp,
    randomAvatar,
    newAvatar,
  } = usePlayerChoice()

  return (
    <>
      {player.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th colSpan={2}>Bisher spielen mit</th>
            </tr>
          </thead>
          <tbody>
            {player.map((p, index) => (
              <tr key={index} onClick={e => handleClick(e, "remove")}>
                <td>
                  <Avatar {...p.avatar} size="small" />
                </td>
                <td>
                  <p>{p.name}</p>
                </td>
                <td>
                  <Button type="submit">-</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}

      {playerCount === 0 ? <h2>Wer spielt mit?</h2> : null}
      <Button onClick={() => toggleModal()}>+</Button>

      <Modal isShowing={isShowing} hide={toggleModal}>
        <Avatar {...randomAvatar} clickHandler={newAvatar} size="big" />
        {/* <h2>{placeholderText}</h2> */}
        <InputWrap>
          <Input
            placeholder="Name"
            hasError={error.hasError}
            onKeyUp={e => handleKeyUp(e)}
          ></Input>
          {error.hasError ? <ErrorText text={error.text} /> : <p>{}</p>}
        </InputWrap>
        <Button type="submit" onClick={e => handleClick(e, "add")}>
          ok
        </Button>
      </Modal>

      {reachedMinimalPlayers ? (
        <ActionMenu>
          <div></div>
          <div>
            <Button gridPosition="right" onClick={() => finishPlayerChoice()}>
              weiter
            </Button>
          </div>
        </ActionMenu>
      ) : null}
      {disabled ? <p>{disableText}</p> : null}
    </>
  )
}
