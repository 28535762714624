import { useState, useEffect, useContext } from "react"
import { TournamentModeContext } from "../../context"
import { IFinalist } from "../../types"

export function useFinalist(props: IFinalist) {
  const { handleFinal, branchWinner, finalWinner } = props
  const { name, branch } = branchWinner
  const { finalIsPlayed, finalIsDeacticvated } = useContext(TournamentModeContext)
  const [isWinner, setIsWinner] = useState(false)
  const [isPlayed, setIsPlayed] = useState(false)
  const columnStart = "3"
  const rowStart = branch === 1 ? "5" : "9"

  const handleClick = e => {
    if (finalIsPlayed) return
    const { name } = e.target.dataset
    handleFinal(name)
  }

  useEffect(() => {
    if (finalIsPlayed) {
      setIsWinner(name === finalWinner ? true : false)
      setIsPlayed(true)
    }
  }, [finalIsPlayed])

  return {
    finalIsDeacticvated,
    name,
    columnStart,
    rowStart,
    handleClick,
    branch,
    isWinner,
    isPlayed,
  }
}
