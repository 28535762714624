import React from "react"

import { usePartyFixtureHeadline } from "./usePartyFixtureHeadline"
interface IPartyFixtureHeadling {
  standings: number
}
export const PartyFixtureHeadline = (props: IPartyFixtureHeadling) => {
  const { standings } = props
  const { headlineText } = usePartyFixtureHeadline(standings)
  return <h2>{headlineText}</h2>
}
