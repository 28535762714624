import React from "react"
import { SmallFinalists } from "./SmallFinalistView.styles"
import { useSmallFinalist } from "./useSmallFinalist"
import {IPlayer} from '../../../../../../types'
export interface ISmallFinalist {
  number: number
  player: IPlayer
}
export const SmallFinalist = (props: ISmallFinalist) => {
  const { number, player } = props

  const { handleClick, smallFinalsPlayerResult, isPlayed, isWinner} = useSmallFinalist(number, player)
  const { name } = player

  return (
    <SmallFinalists
      data-name={name}
      onClick={(e) => handleClick(e)}
      index={number}
      smallFinalsResult={smallFinalsPlayerResult}
      isPlayed={isPlayed}
      isWinner={isWinner}
    >
      {name}
    </SmallFinalists>
  )

}
