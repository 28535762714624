import React from "react"
import { useGameStage } from "./useGameStage"
import { Fixtures } from "../Fixtures"
import { Button } from "../Basic"
import { ActionMenu } from '../ActionMenu'
export const GameStage = () => {
  const { allRoundsPlayed, handleClick } = useGameStage()

  return (
    <>
      <Fixtures />
        {allRoundsPlayed ? (
      <ActionMenu>
          <Button
            onClick={() => handleClick()}
            gridPosition="center"
          >
            beenden
          </Button>
      </ActionMenu>
        ) : null}
    </>
  )
}
