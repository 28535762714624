import React from "react"
import { FixtureWrap } from "../Basic"
import { PvPRound } from "./components"
import { usePvP } from "./usePvP"

export const PvP = () => {
  const { rounds } = usePvP()
  return (
    <FixtureWrap>
      {rounds.length > 0 &&
        rounds.map((round, index) => (
          <PvPRound roundUp={round} key={round + index} />
        ))}
    </FixtureWrap>
  )
}
