import React from "react"
import { TwoOnTwoWrap } from "./TwoOnTwoView.styles"
import { TwoOnTwoTeam } from "./components/2on2Team"
import { use2on2 } from "./use2on2"

export const TwoOnTwo = () => {
  const { teams, isPlayed, setIsPlayed } = use2on2()

  return (
    <TwoOnTwoWrap>
      {/* TODO: anpassen für Mehrspieler */}
      {teams.length > 0 ? (
        <>
          <TwoOnTwoTeam
            team={teams[0]}
            isPlayed={isPlayed}
            setIsPlayed={setIsPlayed}
          />
          <span>vs</span>
          <TwoOnTwoTeam
            team={teams[1]}
            isPlayed={isPlayed}
            setIsPlayed={setIsPlayed}
          />
        </>
      ) : null}
    </TwoOnTwoWrap>
  )
}
