import React from "react"
import styled from "styled-components"

export const LeaderBoardTable = styled.table`
  border-spacing: 0; /* Removes the cell spacing via CSS */
  border-collapse: collapse;
  border-radius: 8px;
  font-size: 1.5em;
  width: 100%;
  tr {
    border: 1px solid ${({ theme }) => theme.kotc_red.normal};
    /* background-color: ${({ theme }) => theme.kotc_orange.normal};
    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.kotc_yellow.normal};
    } */
  }
  thead {
    text-align: left;
  }
  td {
    padding: 0.5em 0;

    &:first-child,
    :last-child {
      padding: 0.5em;
    }
  }
  @media (min-width: 768px) {
  td {
    padding: 1em 0;
    &:first-child,
    :last-child {
      padding: 1em;
    }
  }

  }
`

export const PlayerColumn = styled.td`
  /* display: flex;
  align-items: center; */
`

export const ImagePlaceholder = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.kotc_lightGrey};
  margin-right: 10px;
`