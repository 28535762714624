import React from 'react'
import {ErrorParagraph} from './ErrorTextView.styles'
export interface IErrorTextProps {
  text: string
}

export const ErrorTextView = (props: IErrorTextProps) => {
  const {text} = props
  return (
    <ErrorParagraph>
      {text}
    </ErrorParagraph>
  )
}
