import styled from "styled-components"

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`
export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalStyle = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.kotc_darkGrey};
  position: relative;
  border-radius: 3px;
  max-width: 500px;
  padding: 1em;
  width: 80vw;
  height: 50vh;
  display: flex;
  flex-direction: column;


`
export const ModalClose = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%

`
