import { useEffect, useState } from "react"
import { useLeaderboard } from "../Leaderboard/useLeaderboard"
export function useEndScreen() {
  const [scrollHeight, setScrollHeight] = useState(0)
  const [winner, setWinner] = useState(null)
  const [name, setName] = useState(null)
  const [url, setUrl] = useState(null)
  const { playerdata } = useLeaderboard()

  // useEffect(() => {
  //   setScrollHeight(document.body.scrollHeight)
  // }, [window, document])
  // if (window)
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })

  useEffect(() => {
    if (playerdata && !winner) setWinner(playerdata[0])
  }, [playerdata])

  useEffect(() => {
    if (winner) {
      setName(winner.name)
      setUrl(winner.avatar.url)
    }
  }, [winner])

  return { window, scrollHeight, playerdata, winner, url, name}
}
