import { useState, useEffect, useContext } from "react"
import { GameStageContext } from "../../contexts"
export interface IGameIntro {
  title: String
}

export function useGameIntro(props: IGameIntro) {
  const { title } = props
  const { gameNumber } = useContext(GameStageContext)
  const [gameNumberAnimation, setGameNumberAnimation] = useState(null)
  const [isVisible, setIsVisible] = useState(true)

  function randomAnimation() {
    const animations = ['SlideInLeft', 'SlideInRight', 'SlideInTop', 'SlideInBottom'];
    const animationsCount = animations.length
    return Math.floor(Math.random() * Math.floor(animationsCount))
  }

  useEffect(() => {
    setIsVisible(true)
    setGameNumberAnimation(randomAnimation);
  }, [title])

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("inside timeout")
      setIsVisible(false)
    }, 3000)
    return () => clearTimeout(timeout)
  }, [title])

  return { isVisible, title, gameNumber, gameNumberAnimation }
}
