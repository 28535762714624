import { useState, useContext, useEffect } from "react"
import { PlayerContext, GameStageContext, FixturesContext } from "../../../../contexts"
import { shuffle } from "../../../../utils"
export function use2on2() {
  const { player } = useContext(PlayerContext)
  const [isPlayed, setIsPlayed] = useState(false)
  const [teams, setTeams] = useState([])
  const { gameStageGame } = useContext(GameStageContext)
  const {setRoundPlayed} = useContext(FixturesContext)

  useEffect(() => {
    shuffle(player)
    const team1 = [player[0], player[2]]
    const team2 = [player[1], player[3]]
    setTeams([team1, team2])
  }, [player])

  const playedFixture = () => {
    setIsPlayed(true)
    setRoundPlayed()
  }
  useEffect(() => {
    setIsPlayed(false)
  }, [gameStageGame])

  return { isPlayed, setIsPlayed, teams, playedFixture }
}
