import React from "react"
import { useFixtures } from "./useFixtures"
import { TournamentModeProvider } from "./components/Tournament/context"
import { TwoOnTwo, Party, PvP, Tournament } from "./components"
export const Fixtures = () => {
  const { mode } = useFixtures()

  switch (mode) {
    case "PvP":
      return <PvP />
    case "Party":
      return <Party />
    case "2on2":
      return <TwoOnTwo />
    case "Tournament":
      return (
        <TournamentModeProvider>
          <Tournament />
        </TournamentModeProvider>
      )
    default:
      return <></>
  }
}
