import { useState, useContext, useEffect } from "react"
import { TournamentModeContext } from "../../context"
import { IBranchWinner } from "../../types"
import { FixturesContext } from "../../../../../../contexts"
export function useBranch(number: number, player: any[]) {
  const {
    setFinalists,
    setSmallFinalists,
    finalists,
    semiFinalsPlayed,
    smallFinalsPlayed,
    setRankings,
    setFinalIsPlayed
  } = useContext(TournamentModeContext)
  const {
    setRoundPlayed,
    allRoundsPlayed,
    roundPoints,
    setRoundPoints,
  } = useContext(FixturesContext)
  const [isPlayed, setIsPlayed] = useState(() => false)
  const [branchWinner, setBranchWinner] = useState<IBranchWinner>(() => null)
  const [roundWinner, setRoundWinner] = useState(null)
  const [finalLoser, setFinalLoser] = useState(null)
  const [finalWinner, setFinalWinner] = useState(null)

  const handleBranchPlayed = winner => {
    if (isPlayed) return
    setRoundPlayed()
    setIsPlayed(true)
    setBranchWinner({ name: winner, branch: number })
    handleWinnerAndLoser(winner, player)
  }

  useEffect(() => {
    if (finalLoser) {
      const newScore = roundPoints.filter(player => player.name !== finalLoser)
      setRoundPoints([...newScore, { name: finalLoser, score: 2 }])
    }
  }, [finalLoser])

  // TODO auslagern
  function handleWinnerAndLoser(nameWinner, player) {
    player.forEach(element => {
      if (element.name === nameWinner) {
        setFinalists(prev => [...prev, element])
      } else {
        setSmallFinalists(prev => [...prev, element])
      }
    })
  }
  const handleFinalistClick = (finalWinner) => {
    if (!semiFinalsPlayed || !smallFinalsPlayed || allRoundsPlayed) return
    setRoundPlayed()
    setFinalIsPlayed(true)
    setFinalWinner(finalWinner)
    // TODO: hinweise, dass small final erst
    setRoundWinner(finalWinner)
    // TODO points auslagern
    const newScore = roundPoints.filter(player => player.name !== finalWinner)
    setRoundPoints([...newScore, { name: finalWinner, score: 3 }])
    setFinalLoser(identifyLoser(finalists, finalWinner).name)
  }



  return {
    number,
    player,
    handleBranchPlayed,
    isPlayed,
    handleClick: handleBranchPlayed,
    branchWinner,
    handleFinal: handleFinalistClick,
    semiFinalsPlayed,
    finalWinner,
  }
}
export const identifyLoser = (array, winner) => {
  return array.find(element => element.name !== winner)
}
