import styled from "styled-components"

export const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  grid-column: span 2;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0 20px;

  input {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
`
