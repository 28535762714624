import styled from "styled-components"

interface IPartyPlayerContainer {
  rank: number
  standings: number
}

export const PartyPlayerContainer = styled.div<IPartyPlayerContainer>`
  margin: 0.5em 0;
  border: 1px solid ${({ theme }) => theme.kotc_red.normal};
  background-color: ${({ rank, theme, standings }) =>
    rank === 0 && standings < 3 ? "transparent" : theme.kotc_red.normal};
  color: ${({ rank, theme, standings }) =>
    rank === 0 && standings < 3 ? theme.kotc_red.normal : theme.kotc_creme};

  position: relative;
  font-size: 1.5rem;
  padding: 1em 0;
  cursor: pointer;


  &:before {
    display: ${({ rank }) => (rank > 0 && rank < 4 ? "inline-block" : "none")};
    content: "";
    width: ${({ rank }) =>
      rank === 1 ? "36px" : rank === 2 ? "30px" : "24px"};
    height: ${({ rank }) =>
      rank === 1 ? "30px" : rank === 2 ? "24px" : "18px"};
    background-image: url("/logo_krone.svg");
    position: absolute;
    top: ${({ rank }) =>
      rank === 1 ? "-20px" : rank === 2 ? "-16px" : "-12px"};
    left: ${({ rank }) =>
      rank === 1 ? "-17px" : rank === 2 ? "-13px" : "-9px"};
    background-size: contain;
  }
  @media (min-width: 768px) {
    max-width: 320px;
    margin: 1em auto;
  }
`
